import React from 'react';
import Section from './Section';
import RevealLink from './RevealLink';
import './Contact.css';

const contactInfo = [
  {
    label: 'Email',
    onHover: 'email',
    link: {
      type: 'mailto',
      url: {
        canonical: 'sebastianodell@gmail.com',
        actual: 'mailto:sebastianodell@gmail.com'
      }
    }
  },
  {
    label: 'Twitter',
    onHover: 'twitter',
    link: {
      type: 'external',
      url: {
        canonical: '@illuminatorium',
        actual: 'https://www.twitter.com/illuminatorium'
      }
    }
  },
  {
    label: 'Github',
    onHover: 'github',
    link: {
      type: 'external',
      url: {
        canonical: '@sepowitz',
        actual: 'https://www.github.com/sepowitz',
      }
    }
  }
];

const Contact = () => {
  return (
    <Section
      id="Contact"
      title="contact"
    >
      <p className="Contact__info body body-max-width">
        While I’m currently working fulltime I am open to freelance work from time to time. 
        Please reach out if you are interested in working together.
      </p>
      <ul className="Contact__links-list body">
        {contactInfo.map(contact => (
          <li className="Contact__links-list-item">
            <RevealLink
              label={contact.label}
              url={contact.link.url}
            />
          </li>
        ))}
      </ul>
    </Section>
  );
}

export default Contact;