import React, { useState } from "react";
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import { motion, AnimatePresence } from "framer-motion";

import Header from "./components/Header.js";
import Intro from "./components/Intro";
import Work from "./components/Work.js";
import Capabilities from "./components/Capabilities.js";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

import "./styles/App.css";

const fontConfig = {
  custom: {
    families: ['Beausite Grand'],
    urls: ['./styles/index.css']
  }
};

const layoutTransition = {
  type: "spring",
  damping: 20,
  stiffness: 2
};

const containerVariants = {
  initial: {
    opacity: 0,
    y: 100,
    transition: {
      duration: 0.5
    }
  },
  fadeIn: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.35,
      when: "beforeChildren"
    }
  }
};

const App = () => {
  const [ status, setStatus] = useState('loading');

  const handleStatus = (status) => {
    setStatus(status);
  };

  return (
    <WebfontLoader config={fontConfig} onStatus={handleStatus}>
      <div className="App wrapper-padding">
        <AnimatePresence>
          {status === 'active' ? (
            <>
              <motion.div
                layoutTransition={layoutTransition}
                initial={"initial"}
                animate={"fadeIn"}
                exit={"initial"}
                variants={containerVariants}
              >
                <Header />
                <Intro />
                <Work />
                <Capabilities />
                <Contact />
              </motion.div>
              <Footer />
            </>
          ) : null}
        </AnimatePresence>
      </div>
    </WebfontLoader>
  );
};

export default App;
