import React from "react";
import Section from "./Section";
import "./Capabilities.css";

const capabilities = [
  [
    "Mobile app development",
    "Front-end Development",
    "Back-end Development",
    "Technology Consulting",
    "UX Design/Strategy",
    "UI Design/Protoyping"
  ],
  [
    "React/Redux",
    "React Native",
    "Ember",
    "Ruby/Rails",
    "Elixir/Phoenix",
    "Shopify",
    "CSS/Sass",
    "Aws",
    "Now"
  ]
];

const Capabilities = () => {
  return (
    <Section id="Capabilities" title="capabilities">
      <div className="Capabilities--section-wrapper">
        {capabilities.map(section => (
          <ul className="Capabilities--section">
            {section.map(capability => (
              <li className="Capabilities--section-item body">{capability}</li>
            ))}
          </ul>
        ))}
      </div>
    </Section>
  );
};

export default Capabilities;
