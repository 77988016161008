import React from 'react';
import './Footer.css';
import { Link, animateScroll } from 'react-scroll';

const footerLinks = [
  {
    label: 'top',
    onHover: null,
    title: '',
    link: {
      type: 'anchor',
      url: '#intro'
    }
  },
  {
    label: 'work',
    onHover: null,
    title: 'Work',
    link: {
      type: 'anchor',
      url: '#work'
    }
  },
  {
    label: 'capabilities',
    title: 'Capabilities',
    onHover: null,
    link: {
      type: 'anchor',
      url: '#capabilities'
    }
  },
  {
    label: 'contact',
    title: 'Contact',
    onHover: null,
    link: {
      type: 'anchor',
      url: '#contact'
    }
  },
];

const Footer = () => {
  return (
    <div className="Footer">
      <ul className="Footer__links-list body">
        {footerLinks.map(link => (
          <Link className="Footer__links-list-item" to={link.title} smooth={true} offset={2}>
            <li onClick={!link.title ? () => animateScroll.scrollToTop() : () => {}}>
              {link.label}
            </li>
          </Link>
        ))}
      </ul>
      <div className="Footer__signature">
        <p>SEBASTIAN ODELL</p>
        <p>© MMXIX</p>
      </div>
    </div>
  );
}

export default Footer;