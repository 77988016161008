import React, { useState } from 'react';
import { motion, Variants } from "framer-motion"
import './RevealLink.css';

type RevealLinkProps = {
  label: string,
  url: {
    canonical: string,
    actual: string
  },
  framerMotion?: {
    customValue: number,
    variants: Variants,
  }
};

const linkVariant = {
  fadeIn: {
    opacity: 1,
    x: 0,
  },
  fadeOut: {
    opacity: 0,
    x: -15,
  }
};

const RevealLink: React.FC<RevealLinkProps> = ({
  label,
  url,
  framerMotion
}) => {
  const [isRevealed, setIsRevealed] = useState(false);

  const revealLink = () => setIsRevealed(true);
  const hideLink = () => setIsRevealed(false);

  const renderInner = () => (
    <a href={url.actual} target="_blank" rel="noopener noreferrer">
      <span className="body">
        {label}
      </span>
      {isRevealed && (
        <motion.span 
          className="body" 
          variants={linkVariant} 
          initial="fadeOut" 
          animate="fadeIn" 
          exit="fadeOut"
        >
          {url.canonical}
        </motion.span>
      )}
    </a>
  );

  if (!!framerMotion && !!Object.keys(framerMotion).length) {
    return (
      <motion.div 
        className="RevealLink" 
        custom={framerMotion.customValue} 
        variants={framerMotion.variants} 
        onMouseEnter={revealLink} 
        onMouseLeave={hideLink}
      >
        {renderInner()}
      </motion.div>
    );
  }

  return (
    <div 
      className="RevealLink" 
      onMouseEnter={revealLink} 
      onMouseLeave={hideLink}
    >
      {renderInner()}
    </div>
  )
};

export default RevealLink;