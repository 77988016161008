import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Element, scroller } from 'react-scroll';
import Section from "./Section";
import WorkDetail from "./WorkDetail";
 
import "./Work.css";

// Light Web
import lightHome from "../mocks/images/light-home.png";
import lightHomeSmall from "../mocks/images/light-home-small.png";
import lightProduct from "../mocks/images/light-product.png";
import lightProductSmall from "../mocks/images/light-product-small.png";
import lightCheckout from "../mocks/images/light-checkout.png";
import lightCheckoutSmall from "../mocks/images/light-checkout-small.png";

// Tartine
import tartineHome from "../mocks/images/tartine-home.png";
import tartineHomeSmall from "../mocks/images/tartine-home-small.png";
import tartineMap from "../mocks/images/tartine-map.png";
import tartineMapSmall from "../mocks/images/tartine-map-small.png";
import tartineOlo from "../mocks/images/tartine-olo.png";
import tartineOloSmall from "../mocks/images/tartine-olo-small.png";

// Roti
import rotiMenu from "../mocks/images/roti-menu.png";
import rotiMenuSmall from "../mocks/images/roti-menu-small.png";
import rotiBuild from "../mocks/images/roti-build.png";
import rotiBuildSmall from "../mocks/images/roti-build-small.png";
import rotiCheckout from "../mocks/images/roti-checkout.png";
import rotiCheckoutSmall from "../mocks/images/roti-checkout-small.png";

const workWrapperVariants = {
  expand: {
    height: "auto",
    opacity: 1,
    transition: {
      when: "beforeChildren",
      duration: 1.5
    }
  },
  collapse: {
    height: 0,
    opacity: 0,
    transition: {
      when: "afterChildren",
      duration: 1
    }
  }
};

const work = [
  {
    id: 'light',
    title: "Light Phone",
    description:
      "came to us to build the OS for their second generation device, as well as the backend system, online store, and customer dashboard.",
    images: [lightHome, lightProduct, lightCheckout],
    fallbacks: [lightHomeSmall, lightProductSmall, lightCheckoutSmall],
    link: {
      label: "View website",
      url: {
        canonical: "www.lightphone.com",
        actual: "https://www.lightphone.com"
      }
    }
  },
  {
    id: 'tartine',
    title: "Tartine Bakery",
    description:
      "wanted a revitalized online presence to support their incredible growth, unify their varied offerings, and support online ordering.",
    images: [tartineHome, tartineMap, tartineOlo],
    fallbacks: [tartineHomeSmall, tartineMapSmall, tartineOloSmall],
    link: {
      label: "View website",
      url: {
        canonical: "www.tartinebakery.com",
        actual: "https://www.tartinebakery.com"
      }
    }
  },
  {
    id: 'roti',
    title: "Roti",
    description:
      "wanted a custom online ordering system complete with a custom meal builder, allergen management, and support for pickup and delivery.",
    images: [rotiMenu, rotiBuild, rotiCheckout],
    fallbacks: [rotiMenuSmall, rotiBuildSmall, rotiCheckoutSmall],
    link: {
      label: "View website",
      url: {
        canonical: "ordering.roti.com",
        actual: "https://ordering.roti.com/"
      }
    }
  }
];

const initialWorkState = {
  'light': false,
  'tartine': false,
  'roti': false,
};

const Work = () => {
  const [toggledWorkState, setToggledWorkState] = useState(initialWorkState);

  const toggleWork = (workId) => {
    if (!toggledWorkState[workId]) {
      setToggledWorkState({ ...initialWorkState, [workId]: true });
      return;
    }

    setToggledWorkState({ ...initialWorkState, [workId]: false });
  }

  const handleToggleComplete = (workId) => {
    scroller.scrollTo(workId, {
      duration: 500,
      smooth: true,
      offset: -75, 
    });
  }

  return (
    <Section id="Work" title="select works">
      <div className="Work__projects-list">
        {work.map(pj => (
          <div className="Work__projects-list-item">
            <Element name={pj.id}>
              <div
                  onClick={() => toggleWork(pj.id)}
                >
                  <p className="Work__projects-body body">
                    <span className="title">{pj.title}</span>
                    {pj.description}
                  </p>
                </div>

                <AnimatePresence>
                  {toggledWorkState[pj.id] && (
                    <motion.div
                      initial="collapsed"
                      animate="expanded"
                      exit="collapsed"
                      variants={workWrapperVariants}
                      onAnimationComplete={() => handleToggleComplete(pj.id)}
                    >
                      <WorkDetail link={pj.link} images={pj.images} fallbacks={pj.fallbacks} />
                    </motion.div>
                  )}
                </AnimatePresence>
              </Element>
            </div>
        ))}
        <p className="Work__projects-more small">
          Additional clients include Gagosian, Casper, Open Tender, Dig Inn,
          Dame Products, and Esteban Cortazar.
        </p>
      </div>
    </Section>
  );
};

export default Work;
