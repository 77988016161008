import React from 'react';
import './Intro.css';

const Intro = () => {
  return (
    <div className="Intro body-max-width">
      <p className="body">
        Currently fulltime at Sanctuary Computer, a 
        development studio in NYC’s Chinatown. I split 
        my work between the frontend and backend, 
        implementing everything from interactive design 
        systems with React and Redux, to building robust 
        REST apis with Elixir Phoenix.
      </p>
      <p className="body">
        Outside of work I enjoy watching and playing soccer, 
        biking around the city, and baking sour dough bread.
      </p>
    </div>
  );
}

export default Intro;