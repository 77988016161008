import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import _throttle from "lodash/throttle";
import "./Header.css";

const itemVariants = {
  initial: i => ({
    opacity: 0,
    transition: {
      delay: i * 0.04,
      duration: 0.5
    }
  }),
  fadeIn: i => ({
    opacity: 1,
    transition: {
      delay: i * 0.04,
      duration: 0.5
    }
  }),
  grow: i => ({
    opacity: 1,
    y: 10,
    transition: {
      delay: i * 0.04,
      duration: 0.25
    }
  }),
  shrink: i => ({
    opacity: 0,
    y: -10,
    transition: {
      delay: i * 0.04,
      duration: 0.25
    }
  })
};

const Header = () => {
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = _throttle(() => {
      if (window.pageYOffset >= 1) {
        return controls.start("shrink");
      }

      if (window.pageYOffset < 245) {
        controls.start("grow");
      }
    });

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <>
      <div className="Header__item-container">
        <motion.div custom={3} variants={itemVariants}>
          <motion.div
            className="headline"
            animate={controls}
            custom={2}
            variants={itemVariants}
          >
            Sebastian Odell
          </motion.div>
        </motion.div>
        <motion.div custom={2} variants={itemVariants}>
          <motion.div
            custom={1}
            animate={controls}
            variants={itemVariants}
            className="headline"
          >
            Fullstack Developer
          </motion.div>
        </motion.div>
        <motion.div custom={1} variants={itemVariants}>
          <motion.div
            custom={0}
            animate={controls}
            variants={itemVariants}
            className="headline"
          >
            Brooklyn, NY
          </motion.div>
        </motion.div>
      </div>
    </>
  );
};

export default Header;
