import React from 'react';
import { motion } from "framer-motion"
import RevealLink from './RevealLink';
import SmartImage from './SmartImage';
import './WorkDetail.css';

const itemVariants = {
  fadeIn: (i: number) => ({
    opacity: 1,
    transition: {
      delay: i * 0.08,
      duration: 0.8
    }
  }),
  fadeOut: (i: number) => ({
    opacity: 0,
    transition: {
      delay: i * 0.02,
      duration: 0.5
    }
  }),
};

type Link = {
  label: string,
  url: {
    canonical: string,
    actual: string
  }
};

type WorkDetailProps = {
  images: Array<string>,
  fallbacks: Array<string>,
  link: Link,
  isVisible: boolean
};

const WorkDetail: React.FC<WorkDetailProps> = ({ images, fallbacks, link }) => {
  return (
    <motion.div 
      className="WorkDetail"
      initial={'fadeOut'}
      animate={'fadeIn'}
      exit={'fadeOut'}
    >
      {images && (
        <div className="WorkDetail__image-container">
          {images.map((imageUrl, index) =>
            <motion.div className="WorkDetail__image-wrapper" custom={index} variants={itemVariants}>
              <SmartImage className="WorkDetail__image" src={imageUrl} fallback={fallbacks[index]} />
            </motion.div>
          )}
        </div>
      )}
      <RevealLink 
        label={link.label}
        url={link.url}
        framerMotion={{
          variants: itemVariants,
          customValue: images.length
        }}
      />
    </motion.div>
  );
}

export default WorkDetail;