import React, { useState } from "react";
import { Element } from 'react-scroll';
import { motion } from "framer-motion";
import Sticky from "react-stickynode";
import "./Section.css";

const titleVariants = {
  grow: {
    width: "100%",
    fontSize: '30px',
    transition: {
      duration: 0.25
    }
  },
  shrink: {
    width: 240,
    fontSize: '25px',
    transition: {
      duration: 0.25
    }
  }
};

type SectionProps = {
  title: string,
  id: string
};

type StickyNodeStatus= {
  status: number
};

type Breakpoints = {
  xs?: boolean,
  sm?: boolean, 
  md?: boolean,
  lg?: boolean,
  xl?: boolean
};

const Section: React.FC<SectionProps> = props => {
  const [isGrown, setGrown] = useState(false);

  const handleStickyChange = ({ status }: StickyNodeStatus) => {
    if (status === Sticky.STATUS_FIXED) {
      return setGrown(true);
    } else {  
      return setGrown(false);
    }
  };

  return (
    <div className={`Section Section-${props.id}`}>
      <Element name={props.id}>
        <Sticky
          top={0}
          bottomBoundary={`.Section-${props.id}`}
          onStateChange={handleStickyChange}>
          <motion.div
            className={`Section--title small`}
            animate={isGrown ? "grow" : "shrink"}
            variants={titleVariants}>
            {props.title}
          </motion.div>
        </Sticky>
      </Element>
      <div className="Section__body">{props.children}</div>
    </div>
  );
};

export default Section;
