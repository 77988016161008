import React, { useEffect, useState } from 'react';
import './SmartImage.css';

type Props = {
  src: string,
  alt?: string,
  fallback?: string,
  className?: string,
};

const SmartImage: React.FC<Props> = ({
  src = "", 
  alt = "", 
  fallback,
  className,
}) => {
  const [fallbackHasLoaded, setFallbackHasLoaded] = useState(false);
  const [imageHasLoaded, setImageHasLoaded] = useState(false);

  useEffect(() => {
    if (fallback) {
      const fallbackImage = new Image();
      fallbackImage.src = fallback;
      fallbackImage.onload = () => setFallbackHasLoaded(true);
    }

    const mainImage = new Image();

    mainImage.src = src;
    mainImage.onload = () => setImageHasLoaded(true);
  }, [fallback, src]);

  return (
    <div className="SmartImage">
      {!!fallback && <img className={`SmartImage__fallback ${fallbackHasLoaded ? 'loaded': ''}`} src={fallback} alt={alt} />}
      <img className={`SmartImage__image ${imageHasLoaded ? 'loaded': ''}`} src={src} alt={alt} />
    </div>
  );
};

export default SmartImage;